import {useMemo, useEffect} from 'react';
import {useFormikContext} from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import {Field, Fieldset} from 'components/customer/Product/entity';
import {getFieldValue} from 'shared/helpers';

export interface DrawerPanelEdge {
    drawer_edge_top: number | null;
    drawer_edge_bottom: number | null;
    [key: string]: string | number | boolean;
}

export const useComponentDrawerEdge = (
    fieldset: Fieldset,
    selectHandler: (name: string, value: DrawerPanelEdge[]) => void
) => {
    const {values, setFieldValue} = useFormikContext<{
        drawer_panel_edges: DrawerPanelEdge[];
        drawer_amount: number;
        [key: string]: number | DrawerPanelEdge[];
    }>();

    const quantity = useMemo(() => {
        const quantity = fieldset.quantity;
        if (!isNaN(quantity)) return quantity;

        return Number(values[String(quantity)]) ?? 1;
    }, [values]);

    const fieldsets = useMemo(() => {
        const currentValues = values[fieldset.name];

        if (currentValues && Array.isArray(currentValues)) {
            return currentValues.map((value, index: number) => {
                const clonedFieldSet = cloneDeep(fieldset);

                clonedFieldSet.fields.forEach((field) => {
                    field.displayName = getFieldValue(field.displayName, {
                        fieldset: {index},
                    });
                });

                return clonedFieldSet;
            });
        }

        return [];
    }, [values[fieldset.name]]);

    const generateEdgeValues = (fields: Field[], startIndex: number) => {
        return fields.reduce<DrawerPanelEdge>(
            (acc, field, index) => {
                field.displayName = getFieldValue(field.displayName, {
                    fieldset: {index: startIndex + index + 1},
                });

                acc[field.name] = field.value || 1;
                return acc;
            },
            {
                drawer_edge_top: 0,
                drawer_edge_bottom: 0,
            }
        );
    };

    useEffect(() => {
        if (fieldset.name) {
            let drawerEdges =
                (values[fieldset.name] as DrawerPanelEdge[]) ?? [];

            if (quantity === 1) {
                drawerEdges = [];
            } else if (quantity > drawerEdges.length) {
                const additionalFields: DrawerPanelEdge[] = Array.from(
                    {length: quantity - drawerEdges.length},
                    (_, index) => {
                        const fieldSet = cloneDeep(fieldset);
                        return generateEdgeValues(
                            fieldSet.fields,
                            drawerEdges.length + index
                        );
                    }
                );

                drawerEdges = [...drawerEdges, ...additionalFields];
            } else if (quantity < drawerEdges.length) {
                drawerEdges = drawerEdges.slice(0, quantity);
            }

            if (selectHandler) {
                void selectHandler(fieldset.name, drawerEdges);
            } else {
                void setFieldValue(fieldset.name, drawerEdges);
            }
        }
    }, [quantity, values?.drawer_amount]);

    return {
        fieldName: fieldset.name,
        fieldsets,
    };
};
