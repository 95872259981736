import {useAppContext} from 'contexts';
import {jobActionHandlers, jobSearch, PDFHandler, PDFMenu} from 'hooks';
import {pdfOptionsOrder} from 'shared/helpers';

const jobButtonsHandler = (isSearch = false) => {
    const {userProfile} = useAppContext();
    const {
        copyJobHandler,
        unSubmitJobHandler,
        dialog,
        deleteJobHandler,
        editJobHandler,
        showJobStatus,
    } = jobActionHandlers();
    const {resetResults} = jobSearch();

    const dropdownMenuHandler = (row, event, option) => {
        event.stopPropagation();
        PDFHandler(row, option);
    };

    const isHidden = (row) =>
        userProfile.inActiveManufacturer || row.resetPriceStatus;

    const buttons = {
        title: 'Options',
        width: 50,
        buttons: [
            {
                iconName: 'Options-Edit.svg',
                title: 'Edit Job',
                rowAction: true,
                action: (row, event) => {
                    event.stopPropagation();
                    editJobHandler(row);
                    if (isSearch) resetResults(true);
                },
                isHidden: (row) => {
                    return (row.status == 0 || row.status == 5) &&
                        !(
                            userProfile.inActiveManufacturer ||
                            row.resetPriceStatus
                        )
                        ? false
                        : true;
                },
            },
            {
                iconName: 'Button-Rotate-Anti-Clockwise.svg',
                title: 'Unsubmit Job',
                rowAction: true,
                action: (row, event) => {
                    event.stopPropagation();
                    unSubmitJobHandler(row);
                },
                isHidden: (row) => {
                    return row.status == 1 &&
                        !(
                            userProfile.inActiveManufacturer ||
                            row.resetPriceStatus
                        )
                        ? false
                        : true;
                },
            },
            {
                iconName: 'More-Info.svg',
                title: 'Job Status',
                rowAction: true,
                action: (
                    row: {id: number; webhookResponse: string[]},
                    event: React.MouseEvent
                ) => {
                    event.stopPropagation();
                    showJobStatus(row);
                },
                isHidden: (row: {
                    status: number;
                    webhookResponse: string[];
                }) => {
                    return row.status > 0 && row.webhookResponse ? false : true;
                },
            },
            {
                iconName: 'Options-Copy.svg',
                title: 'Copy Job',
                action: (row, event) => {
                    event.stopPropagation();
                    copyJobHandler(row);
                },
                isHidden,
            },
            {
                iconName: 'Options-PDF.svg',
                isHidden: (row) => {
                    return row.resetPriceStatus;
                },
                title: 'Get PDF',
                dropdown: [
                    {
                        name: PDFMenu.JOB_DETAILS_PDF,
                        show:
                            userProfile.hasOwnProperty(
                                'showJobPropertiesTablePdf'
                            ) && userProfile.showJobPropertiesTablePdf
                                ? true
                                : false,
                        order: pdfOptionsOrder(
                            PDFMenu,
                            PDFMenu.JOB_DETAILS_PDF,
                            userProfile
                        ),
                    },
                    {
                        name: PDFMenu.ORDER_ACKNOWLEDGEMENT_PDF,
                        show:
                            userProfile.hasOwnProperty(
                                'showOrderAcknowledgementPdf'
                            ) && userProfile.showOrderAcknowledgementPdf
                                ? true
                                : false,
                        order: pdfOptionsOrder(
                            PDFMenu,
                            PDFMenu.ORDER_ACKNOWLEDGEMENT_PDF,
                            userProfile
                        ),
                    },
                    {
                        name: PDFMenu.CUSTOMER_INVOICE_PDF,
                        show: (row) =>
                            userProfile.hasOwnProperty(
                                'allowCustomerInvoicingPdf'
                            ) &&
                            userProfile.allowCustomerInvoicingPdf &&
                            row.status > 1
                                ? true
                                : false,
                        order: 5,
                    },
                    {
                        name: PDFMenu.JOB_TOPDOWN_LAYOUT,
                        show: (row) =>
                            row.hasOwnProperty('MaxLayoutStatus') &&
                            row.MaxLayoutStatus >= 2,
                        order: 4,
                    },
                ],
                action: dropdownMenuHandler,
            },
            {
                iconName: 'Options-Delete.svg',
                title: 'Delete Job',
                action: (row, event) => {
                    event.stopPropagation();
                    deleteJobHandler(row);
                },
                isHidden: (row) => {
                    return (row.status == 0 || row.status == 5) &&
                        !(
                            userProfile.inActiveManufacturer ||
                            row.resetPriceStatus
                        )
                        ? false
                        : true;
                },
            },
            {
                iconName: 'tail-spin.svg',
                title: 'Prices being reset',
                isHidden: (row) => {
                    return !row.resetPriceStatus;
                },
                action: () => {},
            },
        ],
    };

    return {buttons, dialog};
};

export {jobButtonsHandler};
