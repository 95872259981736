import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { Icon } from 'shared/helpers';

const ShapeSelection = ({shapes, onSelection, hideDialog}) => {
    return (
        <Row>
        {
            shapes.map( (shape, index) => {
                let icon = '';
                switch(shape.code) {
                    case 'rect':
                        icon = 'RectangularRoom.svg';
                        break;
                    case 'lshape':
                        icon = 'LShapedRoom.svg';
                        break;
                    case 'custom':
                        icon = 'CustomRoom.svg';
                        break;
                }

                return (
                    <Col
                        key={index}
                        style={{
                            textAlign : "center", 
                            cursor : "pointer"
                        }}
                        onClick={
                            () => {
                                onSelection(shape);
                                hideDialog && hideDialog();
                            }
                        }
                    >
                        <Icon 
                            iconName={icon}
                            style={
                                {
                                    width: '75%',
                                    display: 'block',
                                    margin: '0 auto 15px',
                                }
                            }
                            />
                        <strong>{shape.name}</strong>
                    </Col>
                );
            })
        }
        </Row>
    );
}

export const roomShapeSelection = ({showDialog, hideDialog, roomId, tdld, data}) => {
    showDialog({
        title : `Room #${roomId} - Select Room Layout Shape`,
        message : <ShapeSelection hideDialog={hideDialog} shapes={data} onSelection={tdld.onShapeSelected} />,
        hideNoButton : true,
        hideYesButton : true,
        hideFooter : true,
        container : ".mainContent"
    });
}

export const userPrompt = ({showDialog, title, message, buttons, callback}) => {
    let ui_buttons = [], hideConfirmationButtons = false;

    if (typeof buttons === "string") {
        hideConfirmationButtons = true;
        
        ui_buttons = [{
            name : buttons,
            show : true
        }];
    } else if (buttons != null && typeof buttons === "object") {
        hideConfirmationButtons = true;

        ui_buttons = [
            {
                name : buttons.btnYesLabel,
                action: callback,
                show : true
            },
            {
                name : buttons.btnNoLabel,
                show : true
            }
        ];
    }

    showDialog({
        title : title,
        message : message,
        hideYesButton : hideConfirmationButtons,
        hideNoButton : hideConfirmationButtons,
        buttons : ui_buttons,
        yes : () => callback(true)
    });
}

export const showStatus = ({statusText, fadeOut, setStatusMessage, isMounted}) => {
    if (statusText) {
        const statusMessage = { message : statusText };

        if (fadeOut) {
            statusMessage.variant = "success";
            statusMessage.iconName = "Button-Tick.svg";
        } else {
            if (statusText.indexOf("Saving") > -1 || statusText.indexOf("Retrying") > -1) {
                statusMessage.variant = "info";
                statusMessage.iconName = "TailSpinGray.svg";
            } else {
                statusMessage.variant = "danger";
                statusMessage.iconName = "Button-Exclamation.svg";
            }
        }

        isMounted.current && setStatusMessage(statusMessage);

        if (fadeOut) {
            window.setTimeout(() => {
                isMounted.current && setStatusMessage();
            }, 3000);
        }
    }
}