import React, {useEffect, useState} from 'react';
import {getFavouriteItems, getFavouriteProducts} from 'service';
import {genericMessageHandler, usePromise} from 'shared/helpers';
import {useNotificationContext} from 'contexts';
import {saveManageFavourites} from 'service';

export const useFavourites = () => {
    const [loading, setLoading] = useState(false);
    const {notify} = useNotificationContext();
    const [products, setProducts] = useState([]);
    const [sundries, setSundries] = useState([]);

    const saveFavourites = async () => {
        let updatedProductList = [];
        let updatedSundryList = [];

        products.forEach((product, key) => {
            updatedProductList.push({cabinetId: product.id, order: key + 1});
        });

        sundries.forEach((sundry, key) => {
            updatedSundryList.push({sundryId: sundry.id, order: key + 1});
        });
        try {
            setLoading(true);
            let response = await saveManageFavourites(
                updatedProductList,
                updatedSundryList
            );

            genericMessageHandler(notify, {message: response}, 'success');

            setLoading(false);
        } catch (error) {
            genericMessageHandler(notify, error);
            setLoading(false);
        }
    };

    useEffect(() => {
        let cleanUp = () => {};
        setLoading(true);

        let productsPromise = getFavouriteProducts();
        let sundriesPromise = getFavouriteItems();

        cleanUp = usePromise(
            ([products, sundries]) => {
                setProducts(
                    products
                        .map((product) => ({
                            id: product.type,
                            imageUrl: product.image,
                            ...product,
                        }))
                        .sort((a, b) => a.favourites - b.favourites)
                );
                setSundries(
                    sundries.sundry_items.sort(
                        (a, b) => a.favourites - b.favourites
                    )
                );
                setLoading(false);
            },
            [productsPromise, sundriesPromise],
            (error) => {
                genericMessageHandler(notify, error);
            }
        );

        return cleanUp;
    }, []);

    return {
        loading,
        sundries,
        products,
        setProducts,
        setSundries,
        saveFavourites,
    };
};
