import React, {useRef, useState, useEffect} from 'react';
import {searchJob} from 'service';
import {throttle} from 'lodash';
import {useAppContext} from 'contexts';

const jobSearch = () => {
    const delay = 3000;
    const abortController = useRef(null);

    const {
        setSideBarOverlays,
        searchInput,
        resultReceived,
        setResultReceived,
        searchOnText,
        setsearchOnText,
        searchOnButton,
        setsearchOnButton,
        isSearching,
        setisSearching,
    } = useAppContext();

    const [resultData, setResultData] = useState([]);

    const throttleSearch = throttle(
        (e) => {
            executeSearch(e);
        },
        delay,
        {
            leading: false,
            trailing: true,
        }
    );

    /**
     * Handles Throttle before executing Search
     */
    const callSearch = (e) => {
        if (e.keyCode >= 37 && e.keyCode <= 40) return;

        // Escape Key
        if (e.keyCode == 27) {
            throttleSearch.cancel();
            resetResults(true);
            e.target.blur();
            return;
        }

        throttleSearch.cancel();
        setisSearching(false);
        setResultReceived(false);
        cancelRequest();

        if (e.keyCode == 13) {
            executeSearch(e);
        } else {
            throttleSearch(e);
        }
    };

    /**
     * Cancels XHR Request
     */
    const cancelRequest = () => abortController.current && abortController.current.abort();

    /**
     * Execute Search!
     * Only initiate when:
     * 
     * 1. Enter is pressed
     * 2. 3 second no activity
     * 3. 3 Characters minimum
     * 4. Cancel Request, When new keypress activity
     * 
     */
    const executeSearch = async (e) => {
        if (e.target.value.length < 3) return;

        setSideBarOverlays(true);
        setsearchOnButton('searchOnButton');
        setsearchOnText('searchOnText');
        setisSearching(true);
        setResultReceived(false);

        abortController.current = new AbortController();
        setResultData(
            Object.values(await searchJob(e.target.value, abortController.current.signal)
            )
        );

        setisSearching(false);
        setResultReceived(true);
    };

    /**
     * Reset Current Status of Search Bar
     */
    const resetResults = (reset = false) => {
        if (searchInput.current.value == '' || reset) {
            setResultReceived(false);
            setResultData([]);
            setisSearching(false);
            setSideBarOverlays(false);
            setsearchOnButton('');
            setsearchOnText('');
            cancelRequest();
            searchInput.current.value = '';
        }
    };

    useEffect(() => {
        return () => {
            throttleSearch.cancel();
        };
    }),
        [resultData, isSearching, resultReceived];

    return {
        resultData,
        resetResults,
        callSearch,
        searchOnText,
        searchOnButton,
        isSearching,
    };
};

export {jobSearch};
