import {useCallback, useEffect, ReactElement} from 'react';
import {
    getUI,
    UISliceInterface,
    SidebarStatus,
    SidebarBehaviourName,
    SidebarName,
    SidebarBehaviour as SidebarBehaviourType,
    setIsCartToggleVisible as setIsCartToggleVisibleAction,
    setRightSidebarMainContentVisible as setRightSidebarMainContentVisibleAction,
    setLeftSidebarBehaviour as setLeftSidebarBehaviourAction,
    setRightSidebarBehaviour as setRightSidebarBehaviourAction,
    setLeftSidebarStatus as setLeftSidebarStatusAction,
    setRightSidebarStatus as setRightSidebarStatusAction,
    setRightSidebarSoftOpen as setRightSidebarSoftOpenAction,
    setRightSmallScreenBehaviour as setRightSmallScreenBehaviourAction,
    setRightSmallScreenStatus as setRightSmallScreenStatusAction,
    storageValue,
    setSoftOpenSidebarLoaded,
} from 'store/customer/UISlice';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {shallowEqual} from 'react-redux';
import {useAppContext} from 'contexts';

export const SIDEBAR = {
    LEFT: 'left',
    RIGHT: 'right',
};

export type SidebarBehaviour = {
    isCartToggleVisible?: boolean;
    alternateCartContent?: ReactElement;
    showMainCartContent?: boolean;
    leftSidebarBehaviour?: SidebarBehaviourType;
    rightSidebarBehaviour?: SidebarBehaviourType;
    leftSidebar?: SidebarStatus;
    rightSidebar?: SidebarStatus;
    rightSidebarSoftOpen?: boolean;
    setIsCartToggleVisible?: (value: boolean) => void;
    setAlternateCartContent?: (value: ReactElement) => void;
    setShowMainCartContent?: (value: boolean) => void;
    setLeftSidebarBehaviour?: (value: SidebarBehaviourType) => void;
    setRightSidebarBehaviour?: (value: SidebarBehaviourType) => void;
    overrideLeftSidebar?: (value: SidebarStatus) => void;
    overrideRightSidebar?: (value: SidebarStatus) => void;
    setLeftSidebar?: (value: SidebarStatus) => void;
    setRightSidebar?: (value: SidebarStatus) => void;
    restoreLeftSidebar?: (value: SidebarStatus | null) => void;
    restoreRightSidebar?: (value: SidebarStatus | null) => void;
    overrideLeftSidebarBehaviour?: (value: SidebarBehaviourType) => void;
    overrideRightSidebarBehaviour?: (value: SidebarBehaviourType) => void;
    restoreLeftSidebarBehaviour?: (value: SidebarBehaviourType | null) => void;
    restoreRightSidebarBehaviour?: (value: SidebarBehaviourType | null) => void;
    setRightSidebarSoftOpen?: (value: boolean) => void;
    setRightSmallScreenBehaviour?: (value: SidebarBehaviourType) => void;
    setRightSmallScreenStatus?: (value: SidebarStatus) => void;
};

export const useSidebarBehaviour = (smallLayout: boolean): SidebarBehaviour => {
    const dispatch = useAppDispatch();
    const ui = useAppSelector<UISliceInterface>(getUI, shallowEqual);

    const {alternateCartContent, setAlternateCartContent} = useAppContext();

    const softOpenSidebarLoaded = ui.softOpenSidebarLoaded;

    const isCartToggleVisible = ui.isCartToggleVisible;
    const setIsCartToggleVisible = (visible: boolean) =>
        dispatch(setIsCartToggleVisibleAction(visible));

    const showMainCartContent = ui.rightSidebar.mainContentVisible;
    const setShowMainCartContent = (visible: boolean) =>
        dispatch(setRightSidebarMainContentVisibleAction(visible));

    const leftSidebarBehaviour = ui.leftSidebar.behaviour;
    const overrideLeftSidebarBehaviour = (behaviour: SidebarBehaviourType) =>
        dispatch(setLeftSidebarBehaviourAction(behaviour));

    const rightSidebarBehaviour = ui.rightSidebar.behaviour;
    const overrideRightSidebarBehaviour = (behaviour: SidebarBehaviourType) =>
        dispatch(setRightSidebarBehaviourAction(behaviour));

    const leftSidebar = ui.leftSidebar.status;
    const overrideLeftSidebar = (status: SidebarStatus) =>
        dispatch(setLeftSidebarStatusAction(status));

    const rightSidebar = ui.rightSidebar.status;
    const overrideRightSidebar = (status: SidebarStatus) =>
        dispatch(setRightSidebarStatusAction(status));

    const rightSidebarSoftOpen = ui.rightSidebar.softOpen;
    const setRightSidebarSoftOpen = (isSoftOpen: boolean) => {
        dispatch(setRightSidebarSoftOpenAction(isSoftOpen));
    };
    const setRightSmallScreenBehaviour = (behaviour: SidebarBehaviourType) => {
        dispatch(setRightSmallScreenBehaviourAction(behaviour));
    };

    const setRightSmallScreenStatus = (status: SidebarStatus) => {
        dispatch(setRightSmallScreenStatusAction(status));
    };

    const setLeftSidebar = useCallback((status: SidebarStatus): void => {
        localStorage.setItem(
            SidebarName.LEFT,
            SidebarStatus[status as keyof typeof SidebarStatus]
        );
        overrideLeftSidebar(status);
    }, []);

    const setRightSidebar = useCallback(
        (status: SidebarStatus): void => {
            localStorage.setItem(
                SidebarName.RIGHT,
                SidebarStatus[status as keyof typeof SidebarStatus]
            );
            if (
                status == SidebarStatus.OPEN &&
                rightSidebarSoftOpen &&
                !softOpenSidebarLoaded
            ) {
                dispatch(setSoftOpenSidebarLoaded(true));
            }
            overrideRightSidebar(status);
        },
        [softOpenSidebarLoaded, rightSidebarSoftOpen]
    );

    const restoreLeftSidebar = useCallback(
        (defaultValue: SidebarStatus | null = null) => {
            if (defaultValue == null) {
                if (
                    leftSidebarBehaviour == SidebarBehaviourType.TOGGLE &&
                    !smallLayout
                ) {
                    defaultValue = SidebarStatus.OPEN;
                } else {
                    defaultValue = SidebarStatus.CLOSED;
                }
            }

            const leftSidebarStorageValue = storageValue(
                SidebarName.LEFT,
                SidebarStatus[defaultValue as keyof typeof SidebarStatus]
            );

            const status =
                SidebarStatus[
                    leftSidebarStorageValue as keyof typeof SidebarStatus
                ];

            if (typeof status === 'undefined') clearStorage();

            overrideLeftSidebar(status);
        },
        [leftSidebarBehaviour, smallLayout]
    );

    const restoreRightSidebar = useCallback(
        (defaultValue: SidebarStatus = null) => {
            if (defaultValue == null) {
                defaultValue = SidebarStatus.OPEN;
            }

            const rightSidebarStorageValue = storageValue(
                SidebarName.RIGHT,
                SidebarStatus[defaultValue as keyof typeof SidebarStatus]
            );

            const status =
                SidebarStatus[
                    rightSidebarStorageValue as keyof typeof SidebarStatus
                ];

            if (typeof status === 'undefined') clearStorage();

            overrideRightSidebar(status);
        },
        [rightSidebarBehaviour, rightSidebar, smallLayout]
    );

    const setLeftSidebarBehaviour = useCallback(
        (behaviour: SidebarBehaviourType) => {
            localStorage.setItem(
                SidebarBehaviourName.LEFT,
                SidebarBehaviourType[
                    behaviour as keyof typeof SidebarBehaviourType
                ]
            );
            overrideLeftSidebarBehaviour(behaviour);
        },
        []
    );

    const setRightSidebarBehaviour = useCallback(
        (behaviour: SidebarBehaviourType) => {
            localStorage.setItem(
                SidebarBehaviourName.RIGHT,
                SidebarBehaviourType[
                    behaviour as keyof typeof SidebarBehaviourType
                ]
            );
            overrideRightSidebarBehaviour(behaviour);
        },
        []
    );

    const restoreLeftSidebarBehaviour = useCallback(
        (defaultValue: SidebarBehaviourType | null = null) => {
            const leftSidebarStorageValue = storageValue(
                SidebarBehaviourName.LEFT,
                SidebarBehaviourType[
                    defaultValue as keyof typeof SidebarBehaviourType
                ]
            );

            const behaviour =
                SidebarBehaviourType[
                    leftSidebarStorageValue as keyof typeof SidebarBehaviourType
                ];

            if (typeof behaviour === 'undefined') clearStorage();

            overrideLeftSidebarBehaviour(behaviour);

            restoreLeftSidebar(null);
        },
        []
    );

    const restoreRightSidebarBehaviour = useCallback(
        (defaultValue: SidebarBehaviourType | null = null) => {
            const rightSidebarStorageValue = storageValue(
                SidebarBehaviourName.RIGHT,
                SidebarBehaviourType[
                    defaultValue as keyof typeof SidebarBehaviourType
                ]
            );

            const behaviour =
                SidebarBehaviourType[
                    rightSidebarStorageValue as keyof typeof SidebarBehaviourType
                ];

            if (typeof behaviour === 'undefined') clearStorage();

            overrideRightSidebarBehaviour(behaviour);

            restoreRightSidebar(null);
        },
        []
    );

    const clearStorage = () => {
        localStorage.removeItem(SidebarBehaviourName.RIGHT);
        localStorage.removeItem(SidebarBehaviourName.LEFT);
        localStorage.removeItem(SidebarName.LEFT);
        localStorage.removeItem(SidebarName.RIGHT);
    };

    useEffect(() => {
        if (smallLayout) {
            overrideLeftSidebarBehaviour(ui.leftSidebar.smallScreenBehaviour);
            overrideLeftSidebar(ui.leftSidebar.smallScreenStatus);
            overrideRightSidebarBehaviour(ui.rightSidebar.smallScreenBehaviour);
            overrideRightSidebar(ui.rightSidebar.smallScreenStatus);
        } else {
            if (leftSidebarBehaviour == SidebarBehaviourType.AUTOHIDE)
                setLeftSidebarBehaviour(SidebarBehaviourType.TOGGLE);
            else {
                const leftSidebarValue = localStorage.getItem(
                    SidebarBehaviourName.LEFT
                );

                if (!leftSidebarValue) {
                    setLeftSidebarBehaviour(SidebarBehaviourType.TOGGLE);
                    setLeftSidebar(SidebarStatus.CLOSED);
                }
            }

            if (rightSidebarBehaviour == SidebarBehaviourType.AUTOHIDE)
                setRightSidebarBehaviour(SidebarBehaviourType.TOGGLE);
            else {
                const rightSidebarValue = localStorage.getItem(
                    SidebarBehaviourName.RIGHT
                );

                if (!rightSidebarValue) {
                    setRightSidebarBehaviour(SidebarBehaviourType.TOGGLE);
                    setRightSidebar(SidebarStatus.OPEN);
                }
            }
        }
    }, [
        smallLayout,
        leftSidebarBehaviour,
        rightSidebarBehaviour,
        ui.rightSidebar.smallScreenBehaviour,
        ui.leftSidebar.smallScreenBehaviour,
        ui.leftSidebar.smallScreenStatus,
        ui.rightSidebar.smallScreenStatus,
    ]);

    useEffect(() => {
        if (
            ui.rightSidebar.softOpen &&
            ui.rightSidebar.status == SidebarStatus.OPEN &&
            !softOpenSidebarLoaded
        ) {
            overrideRightSidebar(SidebarStatus.CLOSED);
            dispatch(setSoftOpenSidebarLoaded(true));
        }
    }, [
        ui.rightSidebar.status,
        ui.rightSidebar.softOpen,
        softOpenSidebarLoaded,
    ]);

    const setAlternateCartContentHook = useCallback(
        (content: ReactElement) => {
            if (typeof content == 'undefined') {
                setAlternateCartContent(undefined);
                setShowMainCartContent(true);

                return;
            }

            if (content == alternateCartContent) {
                return;
            }

            setAlternateCartContent(content);
            setShowMainCartContent(false);
        },
        [alternateCartContent]
    );

    useEffect(() => {
        setRightSmallScreenStatus(SidebarStatus.CLOSED);
        setRightSmallScreenBehaviour(SidebarBehaviourType.AUTOHIDE);
    }, []);

    return {
        isCartToggleVisible,
        setIsCartToggleVisible,
        alternateCartContent,
        showMainCartContent,
        leftSidebarBehaviour,
        rightSidebarBehaviour,
        leftSidebar,
        rightSidebar,
        setAlternateCartContent: setAlternateCartContentHook,
        setShowMainCartContent,
        overrideLeftSidebar,
        overrideRightSidebar,
        rightSidebarSoftOpen,
        setLeftSidebar,
        setRightSidebar,
        restoreLeftSidebar,
        restoreRightSidebar,
        setLeftSidebarBehaviour,
        setRightSidebarBehaviour,
        overrideLeftSidebarBehaviour,
        overrideRightSidebarBehaviour,
        restoreLeftSidebarBehaviour,
        restoreRightSidebarBehaviour,
        setRightSidebarSoftOpen,
        setRightSmallScreenBehaviour,
        setRightSmallScreenStatus,
    };
};
