import {useRef, useState, useEffect, useCallback} from 'react';
import {getRecords} from 'service';
import {useNotificationContext} from 'contexts';
import {genericMessageHandler} from 'shared/helpers';
import {debounce} from 'lodash';

import type {
    PaginationType,
    OptionsType,
    ParamsType,
    TableRowType,
} from 'shared/types';

// number of pagination links in UI
const MAX_PAGINATION_NUMBERS = 3;

const paginate = (
    totalItems: number,
    currentPage = 1,
    pageSize = 10,
    maxPages = 3
): PaginationType => {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
        currentPage = 1;
    } else if (currentPage > totalPages) {
        currentPage = totalPages;
    }

    let startPage: number;
    let endPage;
    if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
    } else {
        // total pages more than max so calculate start and end pages
        const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
            // current page near the start
            startPage = 1;
            endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
            // current page near the end
            startPage = totalPages - maxPages + 1;
            endPage = totalPages;
        } else {
            // current page somewhere in the middle
            startPage = currentPage - maxPagesBeforeCurrentPage;
            endPage = currentPage + maxPagesAfterCurrentPage;
        }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
        (i) => startPage + i
    );

    // return object with all pager properties required by the view
    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages,
    };
};

type UseTableAPIType = {
    records: TableRowType[];
    pagination: PaginationType;
    setPage: (page: number) => void;
    setRecords: (records: TableRowType[]) => void;
};

export const useTableAPI = (options: OptionsType): UseTableAPIType => {
    const {notify} = useNotificationContext();
    const [records, setRecords] = useState<TableRowType[]>();
    const [page, setPage] = useState<number>(1);
    const [pagination, setPagination] = useState<PaginationType>();
    const endpointReference = useRef<string>();
    const debouncedAPICall = useCallback(
        debounce(
            async (endPoint: string, currentPage: number) => {
                try {
                    const response = await getRecords(endPoint);

                    if (response) {
                        setRecords(response.records);

                        if (typeof options.pagination !== 'undefined') {
                            setPagination(
                                paginate(
                                    response.total,
                                    currentPage,
                                    options.limit,
                                    MAX_PAGINATION_NUMBERS
                                )
                            );
                        }
                    }
                } catch (error) {
                    genericMessageHandler(notify, error);
                }
            },
            600,
            {maxWait: 1000}
        ),
        [options]
    );

    if (typeof options.api === 'undefined') {
        return {records, pagination, setPage, setRecords};
    }

    useEffect(() => {
        const url: string = options.api;
        const params: ParamsType = {};
        let currentPage = page;

        if (endpointReference.current != url) {
            endpointReference.current = url;

            currentPage = 1;
            setPage(1);
        }

        if (typeof records !== 'undefined') {
            setRecords(undefined);
        }

        if (typeof options.pagination !== 'undefined') {
            if (currentPage > 0) {
                params.page = `${currentPage}`;
            }

            if (typeof options.limit !== 'undefined' && !isNaN(options.limit)) {
                params.limit = `${options.limit}`;
            }

            if (typeof options.orderBy !== 'undefined') {
                params.orderBy = options.orderBy.order;
                params.sortBy = options.orderBy.fields[0];
            }
        }

        const endPoint =
            Object.keys(params).length === 0
                ? url
                : url + '?' + new URLSearchParams(params).toString();

        if (endPoint !== '') {
            debouncedAPICall(endPoint, currentPage);
        }
    }, [page, options.api]);

    return {
        records,
        pagination,
        setPage,
        setRecords,
    };
};
