// @flow
import React, {useEffect, useState, useRef} from 'react';
import {useNotificationContext, useKDMaxContext} from 'contexts';
import {
    genericMessageHandler,
    usePromise,
    xmlFileReader,
    xmlFileParser,
} from 'shared/helpers';
import {
    addImportedProducts,
    deleteAllImportedProduct,
    getAllKDMaxProducts,
    getQuickProducts,
} from 'service';
import XMLParserError from 'shared/errors/XMLParserError';
import {useNavigate, useParams} from 'react-router-dom';
import {KDMaxImportMessage} from 'components';
import {contentParser} from 'shared/helpers/KDMaxHelpers';

export const useKDMaxImport = () => {
    const [loader, setLoader] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const {cabinets, setCabinets, showDialog, dialog, hideDialog} =
        useKDMaxContext();

    const {notify} = useNotificationContext();
    const {jobId, roomId} = useParams();
    const navigate = useNavigate();
    const message = useRef({modalVisibility: false, message: ''});

    const handleCheck = (event) => setDisabled(!event.target.checked);

    const displayMessages = () => {
        if (message.current.modalVisibility) {
            showDialog({
                title: 'KD Max Import Complete',
                message: (
                    <KDMaxImportMessage
                        dialogString={message.current.message}
                        handleCheck={handleCheck}
                    />
                ),
                hideYesButton: true,
                hideNoButton: true,
                buttons: [
                    {
                        name: 'Ok',
                        show: true,
                        disabled: disabled,
                        action: () => {
                            navigate(location.pathname);
                            hideDialog();
                        },
                    },
                ],
            });
        }
    };

    // TODO: Uncomment this when the door margin is needed

    // const getDoorDetails = (cabinetDimension, cabinetPosition, doorData) => {
    //     let doorDetails = {};
    //     let doorPositions = [];
    //     let doorDimensions = {};
    //     for (let i = 0; i < doorData.length; i++) {
    //        let doorPosition = doorData[i]['base'][0]['position'][0];
    //        let doorDimension = doorData[i]['base'][0]['size'][0];
    //         doorDimensions[i] = parseFloat(doorDimension.split(',')[1]);

    //         doorPositions[i] = parseFloat(doorPosition.split(',')[2]);
    //     }

    //     let bottomMargin = doorPositions[0] - parseFloat(cabinetPosition[2]);
    //     let topMargin = parseFloat(cabinetDimension[2]) - doorDimensions[0] - bottomMargin;

    //     let leftMargin = doorPositions[0]['y'] - parseFloat(cabinetPosition[0]);
    //     let rightMargin = 0;
    //     let doorGap = 0;
    //     if(doorData.length > 1) {
    //         rightMargin = (parseFloat(cabinetPosition[0]) + parseFloat(cabinetDimension[0])) - (doorPositions[1]['y'] + doorDimensions[1]['width']);
    //         doorGap = doorPositions[1]['y'] - (doorPositions[0]['y'] + doorDimensions[0]['width']);
    //     } else {
    //         rightMargin = (parseFloat(cabinetPosition[0]) + parseFloat(cabinetDimension[0])) - (doorPositions[0]['y'] + doorDimensions[0]['width']);
    //     }

    //     doorDetails = {
    //         'bottomMargin'  : bottomMargin,
    //         'topMargin'     : topMargin,
    //     }
    //     return doorDetails;
    // }

    const fileUploadHandler = async (event) => {
        try {
            const {contents, error} = await xmlFileReader(event.target.files);

            if (error) {
                throw new XMLParserError(error);
            }

            await xmlFileParser(contents);

            for (let i = 0; i < contents.length; i++) {
                const {cabinetData, dialogString} = await contentParser(
                    contents[i],
                    jobId,
                    roomId,
                    genericMessageHandler,
                    notify
                );
                if (cabinetData.length) {
                    await addImportedProducts(cabinetData);
                    message.current.message = dialogString;
                    message.current.modalVisibility = true;
                    setDisabled(true);
                    setLoader(false);
                    displayMessages();
                    setCabinets({
                        type: 'add',
                        payload: [...cabinets, ...cabinetData],
                    });
                }
            }
        } catch (e) {
            event.target.value = '';
            genericMessageHandler(notify, {
                message:
                    e instanceof XMLParserError
                        ? e.message
                        : 'Invalid xml file',
            });
            setLoader(false);
        }
    };

    const deleteAllProducts = async () => {
        setLoader(true);
        deleteAllImportedProduct();
        setCabinets({
            type: 'deleteAll',
            payload: [],
        });
        setLoader(false);
    };

    useEffect(() => {
        displayMessages();
    }, [disabled]);

    useEffect(() => {
        setLoader(true);
        return usePromise(
            ([cabinets]) => {
                cabinets.sort((a, b) => {
                    return a.isAdded === b.isAdded ? 0 : a ? -1 : 1;
                });
                setCabinets({
                    type: 'add',
                    payload: cabinets,
                });

                setLoader(false);
            },
            [getAllKDMaxProducts(roomId), getQuickProducts()],
            (error) => {
                genericMessageHandler(notify, error);
                setLoader(false);
            }
        );
    }, [roomId]);

    return {
        loader,
        cabinets,
        dialog,
        fileUploadHandler,
        deleteAllProducts,
    };
};
