import {useState} from 'react';
import {object, string, ref} from 'yup';
import {useNotificationContext} from 'contexts';
import {genericMessageHandler, randomString} from 'shared/helpers';
import {updatePassword} from 'service';

export const useUpdatePassword = () => {
    const passwordMatch =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,25}$/gm;
    const {notify} = useNotificationContext();

    const [loadingSave, setLoadingSave] = useState(false);
    const [initialValues] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    });

    const PasswordSchema = object().shape({
        oldPassword: string().required('Please enter your old password'),
        newPassword: string()
            .required('Please enter your new password')
            .matches(passwordMatch, 'Please enter a valid password'),
        confirmPassword: string()
            .oneOf([ref('newPassword')], 'Password does not match!')
            .required('Please enter your new password'),
    });

    const generatePassword = () => {
        return randomString(10, true);
    };

    const passwordSubmitHandler = async (values) => {
        setLoadingSave(true);

        const formData = new FormData();
        formData.append('old_password', values.oldPassword);
        formData.append('new_password', values.newPassword);
        formData.append('new_password_2', values.confirmPassword);

        try {
            const response = await updatePassword(formData);
            genericMessageHandler(
                notify,
                {message: response.messages},
                'success'
            );

            setLoadingSave(false);
        } catch (error) {
            genericMessageHandler(notify, error);
            setLoadingSave(false);
        }
    };

    return {
        initialValues,
        loadingSave,
        PasswordSchema,
        passwordSubmitHandler,
        generatePassword,
    };
};
