import {useEffect, useRef, useMemo, useCallback} from 'react';
import {useFormikContext} from 'formik';
import {genericMessageHandler, getFieldValue} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {useStickyCanvas} from 'hooks';
import {
    useAppContext,
    useNotificationContext,
    useProductContext,
} from 'contexts';
import {ComponentPainter, COMPONENT_TYPES} from 'shared';
import {cloneDeep} from 'lodash';

export const useDrillings = (fields, fieldset) => {
    const {values, setFieldValue} = useFormikContext();
    const drillingFieldsets = useMemo(() => {
        if (values.drillings && Array.isArray(values.drillings)) {
            return new Array(values.drillings.length).fill(cloneDeep(fieldset));
        }

        return [];
    }, [values.drillings, fieldset]);

    const addDrillingSet = useCallback(() => {
        const drillings = values.hasOwnProperty('drillings')
            ? cloneDeep(values.drillings)
            : [];

        const drilling = {};
        fieldset.fields.forEach(
            (field) =>
                (drilling[field.name] = field.value === null ? 0 : field.value)
        );

        if (
            drilling.hasOwnProperty('drilling_pitch') &&
            drilling.drilling_pitch == 0
        ) {
            drilling.drilling_pitch = 32;
        }

        drillings.push(drilling);

        setFieldValue('drillings', drillings);
    }, [values.drillings, fieldset]);

    const updateDrillingSet = useCallback(
        (index, deleteDrilling = false) => {
            const positions = cloneDeep(values.drillings);

            if (deleteDrilling) {
                positions.splice(index, 1);
            } else {
                positions.splice(index + 1, 0, cloneDeep(positions[index]));
            }

            setFieldValue('drillings', positions);
        },
        [values.drillings]
    );

    const fieldHandler = useCallback(
        (name, value, index) => {
            const drillings = cloneDeep(values.drillings);
            drillings[index][name] = value;
            setFieldValue('drillings', drillings);
        },
        [values.drillings]
    );

    return {
        drillingFieldsets,
        addDrillingSet,
        updateDrillingSet,
        fieldHandler,
    };
};

export const useDrillingsPreview = () => {
    const position = useStickyCanvas();
    const {values} = useFormikContext();
    const {notify} = useNotificationContext();
    const {currentTab} = useProductContext();
    const isSmallDevice = isDeviceSmall();
    const canvasContainerId = useRef('canvas-container-drilling').current;

    useEffect(() => {
        try {
            const componentPainter = new ComponentPainter(
                canvasContainerId,
                isSmallDevice ? 300 : 500
            );

            componentPainter.init({
                type: COMPONENT_TYPES.DRILLING,
                length: parseInt(values.cabinet_panel_length),
                width: parseInt(values.cabinet_panel_width),
                drillings: values.drillings,
                holeDisplayColor: 'black',
                edgeMaterial: '/templates/wood2.jpg',
                exteriorMaterial: '/templates/young_beech.jpg',
            });
        } catch (error) {
            genericMessageHandler(notify, error);
        }
    }, [values.drillings, currentTab]);

    return {position, canvasContainerId};
};

export const useDoorDrillings = (
    fields,
    fieldset,
    isQFP = false,
    selectHandler = false
) => {
    const {values, setFieldValue} = useFormikContext();
    const {userProfile} = useAppContext();
    const fieldName = useRef('drillings').current;

    const fieldSets = useMemo(() => {
        if (
            values.drillings &&
            values.drillings.length &&
            Array.isArray(values.drillings)
        ) {
            return values.drillings.map((drilling, index) => {
                const hingeHole = cloneDeep(fieldset);

                if (index <= 1) {
                    hingeHole.title = `{IF(fieldSet.index = 0, 'Top', 'Bottom')} ${hingeHole.title}`;
                } else {
                    hingeHole.title = `${hingeHole.title} #{fieldSet.index + 2}`;
                }

                hingeHole.fields = hingeHole.fields.map((field) => {
                    if (
                        field.displayName &&
                        field.displayName.indexOf('{') > -1 &&
                        field.displayName.indexOf('}') > -1
                    ) {
                        field.displayName = getFieldValue(field.displayName, {
                            fieldSet: {index},
                        });
                    }

                    field.name = 'drilling_offset_y';
                    field.options = {...fieldset.options};

                    return field;
                });

                hingeHole.order = index === 1 ? 9999 : index + 1;

                return hingeHole;
            });
        }

        return [];
    }, [values.drillings, fieldset]);

    const autoSize = (drillings, offsetY) => {
        const height = isQFP ? values.height : values.cabinet_panel_length; // use value from structure if is provided later
        let bottomHinge = drillings.find((d, index) => index === 1);

        bottomHinge = bottomHinge
            ? parseFloat(bottomHinge['drilling_offset_y'])
            : offsetY;

        const middleSection =
            height -
            parseFloat(
                drillings
                    .filter((d, index) => index <= 1)
                    .reduce(
                        (a, b) =>
                            parseFloat(a['drilling_offset_y']) +
                            parseFloat(b['drilling_offset_y'])
                    )
            );
        const spacing = middleSection / (drillings.length - 1);

        return drillings.map((drilling, index) => {
            if (index > 1) {
                return {
                    drilling_offset_y: Math.ceil(
                        (bottomHinge ? bottomHinge : 0) +
                            spacing * (drillings.length - (index + 1) + 1)
                    ),
                };
            }

            return drilling;
        });
    };

    const addHingeHole = (count = 0) => {
        if (fieldSets.length >= 5) {
            return;
        }

        let drillings =
            values.hasOwnProperty(fieldName) && values[fieldName].length
                ? JSON.parse(JSON.stringify(values[fieldName]))
                : [];
        let offsetY = 96;
        if (userProfile?.hasOwnProperty('manufacturerDefaultHingePosition')) {
            offsetY = userProfile?.manufacturerDefaultHingePosition;
        }

        const limit = drillings.length + count;
        for (let i = 0; i < limit; i++) {
            const drilling = drillings[i];

            if (typeof drilling === 'undefined') {
                drillings.push({drilling_offset_y: offsetY});
            }

            drillings = autoSize(drillings, offsetY);
        }

        if (drillings.length !== values[fieldName].length) {
            if (selectHandler) selectHandler(fieldName, drillings);
            else setFieldValue(fieldName, drillings);
        }
    };

    const createEvenSpace = () => {
        let drillings = values.hasOwnProperty(fieldName)
            ? JSON.parse(JSON.stringify(values[fieldName]))
            : [];

        drillings = autoSize(drillings);

        if (selectHandler) selectHandler(fieldName, drillings);
        else setFieldValue(fieldName, drillings);
    };

    const deleteHingeHole = (index) => {
        const drillings = values.hasOwnProperty(fieldName)
            ? cloneDeep(values[fieldName])
            : [];

        drillings.splice(index, 1);

        if (selectHandler) selectHandler(fieldName, drillings);
        else setFieldValue(fieldName, drillings);
    };

    useEffect(() => {
        if (values[fieldName] && values[fieldName].length == 0) {
            addHingeHole(2);
        } else {
            addHingeHole();
        }
    }, [values, userProfile]);

    return {
        fieldName,
        fieldSets,
        addHingeHole,
        deleteHingeHole,
        createEvenSpace,
    };
};
