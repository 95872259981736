import {sendAnalyticsEvent} from 'shared/helpers/Analytics';
import * as WebVitals from 'web-vitals';

const getAnalyticsCallback =
    (format = (d: number) => d) =>
    ({name, delta, id}: WebVitals.Metric) =>
        sendAnalyticsEvent(name, 'Web Vitals', {
            value: Math.round(format(delta)),
            label: id,
            nonInteraction: true,
        });

export const useWebVitals = () => {
    WebVitals.onCLS(getAnalyticsCallback((d) => d * 1000));
    WebVitals.onINP(getAnalyticsCallback());
    WebVitals.onLCP(getAnalyticsCallback());
    WebVitals.onFCP(getAnalyticsCallback());
    WebVitals.onTTFB(getAnalyticsCallback());
};
