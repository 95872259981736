// @flow
import React, {useMemo} from 'react';
import {Row, Col} from 'react-bootstrap';
import {
    DoorDrillings,
    DoorSubPanels,
    DrawerFaces,
    GenericFields,
    RailPositions,
    NZPGRotatedEdgeFields,
} from 'components/customer/Product';
import {Formik} from 'formik';
import excel from 'shared/Excel';
import {cloneDeep} from 'lodash';
import {Loader, moveItemToLast} from 'shared/helpers';
import {isDeviceMid, isDeviceLarge} from 'shared/helpers/DeviceSize';
import {Product} from 'components/customer/Product/entity/Product';
import {Structure} from 'components/customer/Product/entity/Structure';
import {ValidationDataInterface} from 'components/customer/QFPRedux/store/qfpSlice';
import {ProductProvider} from 'contexts';
import {MidRails} from 'components/customer/Product/Specs/MidRails';
import {DrawerEdges} from 'components/customer/Product/Specs/DrawerEdges';

type NonGenericType = {
    title: string,
    className: string,
    children: React$Element<any>,
    colSpan?: number,
};

const NonGeneric = ({
    title,
    className,
    children,
    colSpan = 3,
}: NonGenericType): React$Element<any> => {
    const deviceIsMedium = isDeviceMid();
    const colSpan_ = deviceIsMedium ? 6 : colSpan;

    return (
        <Col md={colSpan_} className={className}>
            <div className="fieldset-title">
                <strong>{title}</strong>
            </div>
            <br />
            {children}
        </Col>
    );
};

export const useQuickProductFormFields = (
    isOpen: boolean,
    index: number,
    validStructure: Structure[],
    validationData: ValidationDataInterface,
    product: Product,
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
    ) => void,
    updatingStructure: boolean,
    showLoading = false,
    withFormik = true
): React.JSX => {
    const largeSizedDevice = isDeviceLarge();

    return useMemo<React$Element<any>>(() => {
        if (
            (isOpen && validStructure && !validStructure.length) ||
            updatingStructure ||
            typeof validationData === 'undefined'
        ) {
            if (showLoading) return <Loader loader={true} />;
            else return <></>;
        }

        if (validStructure) {
            let isAdvanced = product.hasOwnProperty('isAdvanced')
                ? product.isAdvanced
                : false;

            if (validationData.hasOwnProperty('isAdvanced')) {
                isAdvanced = validationData.isAdvanced;
            }

            const validation = {
                cabinet_door: {
                    advanced: isAdvanced || isAdvanced == 1,
                },
                ...validationData,
            };

            let validStructureSorted = null;

            if (validStructure?.length)
                validStructureSorted = moveItemToLast(
                    validStructure,
                    (vs) => vs.name?.toLowerCase() == 'specs'
                );

            const fieldsets = cloneDeep(
                validStructureSorted || validStructure
            ).map((config) => {
                return config.fieldsets.map((fieldset, fieldsetIndex) => {
                    fieldset.fields = fieldset.fields.map((field) => {
                        if (
                            field.options.hasOwnProperty('enabled') &&
                            typeof field.options.enabled !== 'boolean'
                        ) {
                            try {
                                field.options.enabled = excel.calculate(
                                    field.options.enabled,
                                    {...product, ...validation}
                                );
                            } catch (e) {
                                field.options.enabled = false;
                            }
                        }

                        if (
                            field.options.hasOwnProperty('visible') &&
                            typeof field.options.visible !== 'boolean'
                        ) {
                            try {
                                field.options.visible = excel.calculate(
                                    field.options.visible,
                                    {...product, ...validation}
                                );
                            } catch (e) {
                                field.options.visible = false;
                            }
                        }

                        return field;
                    });

                    let isVisible = fieldset.fields
                        .map((field) =>
                            field.options.hasOwnProperty('visible')
                                ? field.options.visible
                                : true
                        )
                        .some(Boolean);

                    if (
                        isVisible &&
                        fieldset.options.hasOwnProperty('visible')
                    ) {
                        isVisible = excel.calculate(
                            fieldset.options.visible,
                            validation
                        );
                    }

                    if (!isVisible)
                        return (
                            <React.Fragment
                                key={fieldsetIndex}></React.Fragment>
                        );

                    if (fieldset.name === 'drawer_panel_edges') {
                        return (
                            <NonGeneric
                                colSpan={4}
                                key={Number(fieldsetIndex)}
                                title={fieldset.title}
                                className="drawer_panel_edges">
                                <DrawerEdges
                                    fields={fieldset.fields}
                                    fieldset={fieldset}
                                    isQFP={true}
                                    selectHandler={setFieldValue}
                                />
                            </NonGeneric>
                        );
                    }

                    if (fieldset.name === 'mid_rails') {
                        return (
                            <NonGeneric
                                key="mid_rails_wrapper"
                                title={fieldset.title}
                                className="mid_rails"
                                colSpan={largeSizedDevice ? 3 : 4}>
                                <MidRails
                                    fieldset={fieldset}
                                    isQFP={true}
                                    selectHandler={setFieldValue}
                                />
                            </NonGeneric>
                        );
                    }

                    if (fieldset.name === 'hori_mid_rail_positions') {
                        return (
                            <RailPositions
                                key={fieldsetIndex}
                                fieldset={fieldset}
                                isQFP={true}
                                fieldName="rail_hori"
                                selectHandler={setFieldValue}
                            />
                        );
                    }

                    if (fieldset.name === 'vert_mid_rail_positions') {
                        return (
                            <RailPositions
                                key={fieldsetIndex}
                                fieldset={fieldset}
                                isQFP={true}
                                fieldName="rail_vert"
                                selectHandler={setFieldValue}
                            />
                        );
                    }

                    if (fieldset.name === 'drawer_face_heights') {
                        if (product.drawer_amount == 0) {
                            return (
                                <React.Fragment
                                    key={fieldsetIndex}></React.Fragment>
                            );
                        }

                        return (
                            <NonGeneric
                                colSpan={largeSizedDevice ? 2 : 3}
                                key={fieldsetIndex}
                                title={fieldset.title}
                                className="drawer_face_heights">
                                <DrawerFaces
                                    fields={fieldset.fields}
                                    fieldset={fieldset}
                                    isQFP={true}
                                    selectHandler={setFieldValue}
                                />
                            </NonGeneric>
                        );
                    }

                    if (fieldset.name === 'advanced_door_glass') {
                        return (
                            <NonGeneric
                                key={fieldsetIndex}
                                title={fieldset.title}
                                className="advanced_door_glass"
                                colSpan={3}>
                                <DoorSubPanels
                                    fields={fieldset.fields}
                                    fieldset={fieldset}
                                    isQFP={true}
                                    selectHandler={setFieldValue}
                                />
                            </NonGeneric>
                        );
                    }

                    if (fieldset.name === 'door_drillings') {
                        return (
                            <NonGeneric
                                colSpan={largeSizedDevice ? 3 : 4}
                                key={fieldsetIndex}
                                title={fieldset.title}
                                className="qfp-door-drillings">
                                <DoorDrillings
                                    fields={fieldset.fields}
                                    fieldset={fieldset}
                                    isQFP={true}
                                    selectHandler={setFieldValue}
                                />
                            </NonGeneric>
                        );
                    }

                    if (
                        (fieldset.name === 'door_edges' ||
                            fieldset.name === 'cabinet_edges') &&
                        fieldset.options.hasOwnProperty(
                            'qfpRotateBorderFields'
                        ) &&
                        fieldset.options.qfpRotateBorderFields == 1
                    ) {
                        return (
                            <NonGeneric
                                key={fieldsetIndex}
                                title={fieldset.title}
                                className="qfp-rotated-edge-fields">
                                <NZPGRotatedEdgeFields
                                    fields={fieldset.fields}
                                    fieldset={fieldset}
                                    isQFP={true}
                                    selectHandler={setFieldValue}
                                />
                            </NonGeneric>
                        );
                    }

                    return (
                        <GenericFields
                            key={fieldsetIndex}
                            fields={fieldset.fields}
                            fieldset={fieldset}
                            fieldsetName={fieldset.name}
                            fieldsetTitle={fieldset.title}
                            isQFP={true}
                            fieldsetOptions={fieldset.options}
                            selectHandler={setFieldValue}
                        />
                    );
                });
            });

            if (withFormik) {
                return (
                    <Formik
                        onSubmit={() => {}}
                        initialValues={product}
                        enableReinitialize={true}>
                        {() => <Row>{fieldsets}</Row>}
                    </Formik>
                );
            } else {
                return (
                    <ProductProvider index={index} materials={validation}>
                        <Row>{fieldsets}</Row>
                    </ProductProvider>
                );
            }
        }

        return <></>;
    }, [
        isOpen,
        index,
        validStructure,
        validationData,
        product,
        updatingStructure,
    ]);
};
