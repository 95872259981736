/* eslint-disable @typescript-eslint/restrict-template-expressions */
/**
 * PDF Constants
 */
export const PDFMenu = {
    JOB_DETAILS_PDF: 'Job Details PDF',
    ORDER_ACKNOWLEDGEMENT_PDF: 'Quote PDF',
    JOB_TOPDOWN_LAYOUT: 'Job Topdown Layout',
    CUSTOMER_INVOICE_PDF: 'Tax Invoice PDF',
};

export const PDFHandler = (row: object, option: string): void => {
    switch (option) {
        case PDFMenu.JOB_DETAILS_PDF:
            window.open(`/api/job/${row.id}/pdf/details`);
            break;

        case PDFMenu.ORDER_ACKNOWLEDGEMENT_PDF:
            window.open(`/orderAcknowledgementPdf.php?jobId=${row.id}`);
            break;

        case PDFMenu.JOB_TOPDOWN_LAYOUT:
            window.open(`/jobTopDownLayoutPDF.php?jobId=${row.id}`);
            break;

        case PDFMenu.CUSTOMER_INVOICE_PDF:
            window.open(`/jobPDF.php?jobId=${row.id}`);
            break;
    }
};
