import {useEffect, useState} from 'react';
import {genericMessageHandler, usePromise} from 'shared/helpers';
import {getLeadTimeData} from 'service';
import {useDashboardContext, useNotificationContext} from 'contexts';
import {useTheme} from 'styled-components';

export const useLeadTime = () => {
    const {notify} = useNotificationContext();
    const {setHasLeadTime} = useDashboardContext();
    const theme = useTheme();

    const [loader, setLoader] = useState(false);
    const [graphData, setGraphData] = useState([]);
    const [options, setOptions] = useState({
        colors: [
            theme.colors.secondary.main || '#1D91C0',
            theme.colors.primary.main || '#C8EAB7',
        ],
        hAxis: {
            title: 'Working Days',
        },
        legend: {position: 'top', maxLines: 3},
        series: '',
        chartArea: {left: 130, top: 30, bottom: 40, width: '90%'},
        fontName: theme.typography.fontStyle.fontFamily,
    });

    useEffect(() => {
        setLoader(true);
        return usePromise(
            ([leadTimeData]) => {
                let flatpack = 0;
                let assembled = 0;

                leadTimeData.forEach(function (entry) {
                    if (entry[1] > 0 || entry[2] > 0) {
                        flatpack += parseInt(entry[1]);
                        assembled += parseInt(entry[2]);
                    }
                });

                let hideSeries = '';
                if (flatpack == 0) hideSeries = {0: {visibleInLegend: false}};
                else if (assembled == 0)
                    hideSeries = {1: {visibleInLegend: false}};

                const updatedOptions = JSON.parse(JSON.stringify(options));
                updatedOptions.series = hideSeries;

                setOptions(updatedOptions);

                if (leadTimeData.length == 0) {
                    setHasLeadTime(false);
                } else {
                    setHasLeadTime(true);
                    setGraphData(leadTimeData);
                }
                setLoader(false);
            },
            [getLeadTimeData()],
            (errors, isCancelled) => {
                genericMessageHandler(notify, errors);

                if (!isCancelled.value) {
                    setLoader(false);
                    setHasLeadTime(false);
                }
            }
        );
    }, []);

    return {
        graphData,
        loader,
        options,
    };
};
