import {useCallback, useEffect, useMemo, useState} from 'react';
import {getProductStyles, getQuickProducts, updateFavourite} from 'service';
import {useNotificationContext} from 'contexts';
import {genericMessageHandler, productFilter, usePromise} from 'shared/helpers';
import {Product, ProductStyle, ProductSubStyle} from 'IndexedDB';

const FAVOURITE_CATEGORY_NAME = 'Favourites';

export type ProductSearchCategories =
    | []
    | [ProductStyle]
    | [ProductStyle, ProductSubStyle];

interface ProductSearchParams {
    text: string;
    categories: ProductSearchCategories;
    search: boolean;
}

export const useQuickProductBehaviour = (embedded = false) => {
    const {notify} = useNotificationContext();
    const [products, setProducts] = useState<Product[]>([]);
    const [rawCategories, setRawCategories] = useState<ProductStyle[]>([]);
    const [loading, setLoading] = useState(false);
    const [searchProducts, setSearchProducts] = useState<ProductSearchParams>({
        text: '',
        categories: [],
        search: false,
    });

    const resetSearch = useCallback(() => {
        setSearchProducts({
            text: '',
            categories: [],
            search: false,
        });
    }, [setSearchProducts]);

    const popCategory = useCallback(() => {
        const updatedCategories = searchProducts.categories;
        updatedCategories.pop();

        setSearchProducts({
            ...searchProducts,
            ...{categories: updatedCategories},
        });
    }, [searchProducts]);

    const setSearchText = useCallback(
        (text: string) => {
            if (text === 'lshape' || text === 'ls') {
                text = 'l shape';
            }

            setSearchProducts({
                categories: searchProducts.categories,
                text,
                search: true,
            });
        },
        [searchProducts.categories, setSearchProducts]
    );

    const setSearchCategories = useCallback(
        (categories: ProductSearchCategories) =>
            setSearchProducts({
                categories,
                text: searchProducts.text,
                search: true,
            }),
        [searchProducts]
    );

    const setFavourite = useCallback(
        async (itemId: number, isNew: boolean) => {
            setLoading(true);

            await updateFavourite(itemId, isNew, true);

            const newProducts = await getQuickProducts();

            setProducts(newProducts);

            setLoading(false);
        },
        [setProducts, setLoading]
    );

    // Load products on first search attempt
    useEffect(() => {
        if (searchProducts.search && products.length == 0) {
            setLoading(true);

            usePromise(
                ([quickProducts, categories]: [Product[], ProductStyle[]]) => {
                    setProducts(quickProducts);
                    setRawCategories(categories);
                    setLoading(false);
                },
                [getQuickProducts(), getProductStyles()],
                (error) => {
                    genericMessageHandler(notify, error);
                    setLoading(false);
                }
            );
        }
    }, [searchProducts, products]);

    // Only show categories we have products for
    const categories = useMemo(() => {
        if (rawCategories.length == 0 || products.length == 0) {
            return [];
        }

        const knownCategoriesRaw = products.map((product) => {
            return product.style;
        });

        const knownCategories = knownCategoriesRaw.filter(
            (v, i) => knownCategoriesRaw.indexOf(v) == i
        );

        const categories = knownCategories.map((v) =>
            rawCategories.find((c) => c.id == v)
        );

        categories.push({
            id: -1,
            styleName: FAVOURITE_CATEGORY_NAME,
            styleImage: null,
            subStyles: [],
        });

        return categories;
    }, [products]);

    // Only show subcategories we have products for _and_ belong to the selected parent category
    const subCategories = useMemo(() => {
        if (
            products.length == 0 ||
            searchProducts.categories.length == 0 ||
            searchProducts.categories[0].styleName == FAVOURITE_CATEGORY_NAME
        ) {
            return [];
        }

        const parentCategory = searchProducts.categories[0];

        const knownSubCategoriesRaw = products
            .filter((p) => p.style == parentCategory.id)
            .map((p) => p.subStyleId);

        const knownSubCategories = knownSubCategoriesRaw.filter(
            (v, i) => knownSubCategoriesRaw.indexOf(v) == i
        );

        return knownSubCategories
            .map((v) => parentCategory.subStyles.find((c) => c.subStyleId == v))
            .filter((c) => !!c);
    }, [products, searchProducts, categories]);

    const filteredProducts = useMemo(() => {
        return productFilter(
            products,
            searchProducts.categories.length > 0 &&
                searchProducts.categories[0].styleName ===
                    FAVOURITE_CATEGORY_NAME,
            searchProducts.categories.length > 0 &&
                searchProducts.categories[0].styleName !==
                    FAVOURITE_CATEGORY_NAME
                ? [searchProducts.categories[0].id]
                : [],
            searchProducts.categories.length > 1
                ? [searchProducts.categories[1].subStyleId]
                : [],
            searchProducts.text
        );
    }, [products, searchProducts]);

    return {
        categories,
        subCategories,
        setFavourite,
        products: filteredProducts,
        loading,
        productListVisible:
            (products.length > 0 && searchProducts.search) || embedded,
        showClearButton: searchProducts.text.length > 0,
        searchProducts,
        setSearchCategories,
        setSearchText,
        popCategory,
        resetSearch,
    };
};
