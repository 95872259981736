import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useReducer,
    useRef,
    useState,
} from 'react';
import {useAppContext, useJobContext} from 'contexts';
import {useConfirmationDialog} from 'shared';
import {LayoutMenu} from 'components';
import {TDLD, UiAction} from '@cabinetsbycomputer/top-down-layout-designer';
import {roomShapeSelection, userPrompt, showStatus} from './DesignerFunctions';
import {cloneDeep, debounce} from 'lodash';
import {useComponentMountedHelper} from 'shared/helpers';
import {useNavigate} from 'react-router-dom';
import {setRoomLayoutStatus} from 'service';
import {useAppDispatch} from 'store/customer';
import {invalidateProductAndCost} from 'components/customer/Product/store/productApi';
import {useDebouncedCallback} from 'use-debounce';
import useUI from 'shared/useUI';
import {SidebarBehaviour} from 'store/customer/UISlice';
import {
    useRightSidebarBehaviour,
    useRightSidebar,
} from 'shared/components/Sidebar';
import {invalidateJob} from 'components/customer/Job/store/jobApi';

export const useLayoutDesigner = (jobId, roomId) => {
    const {userProfile} = useAppContext();
    const {setAlternateCartContent} = useUI();
    const {room} = useJobContext();
    const {dialog, showDialog, hideDialog} = useConfirmationDialog();
    const {isMounted} = useComponentMountedHelper();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [loader, setLoader] = useState(true);
    const [statusMessage, setStatusMessage] = useState();
    const [showLayoutSettings, setShowLayoutSettings] = useState(false);
    const [isRoomShapeConfirmed, setIsRoomShapeConfirmed] = useState(false);
    const {openSmallScreenRightSidebar} = useRightSidebar();

    useRightSidebarBehaviour({
        behavior: SidebarBehaviour.TOGGLE,
    });

    const [tdldState, reduceTDLDData] = useReducer(
        ({tdldData: originalTdldData}, action) => {
            const tdldData = cloneDeep(originalTdldData);

            const actionSwitch = (type, payload) => {
                switch (type) {
                    case 'filter':
                        tdldData.filter = payload;
                        break;

                    case 'data':
                        tdldData.data = payload;
                        break;

                    case 'shape':
                        tdldData.shape = payload;
                        break;

                    case 'dimension':
                        tdldData.dimension = payload;
                        break;

                    case 'cabinet':
                        tdldData.cabinet = payload;
                        break;
                }
            };

            if (typeof action.type === 'string') {
                actionSwitch(action.type, action.payload);
            } else {
                action.type.forEach((individualAction, index) =>
                    actionSwitch(
                        individualAction,
                        action.payload[Number(index)]
                    )
                );
            }

            return {tdldData};
        },
        {tdldData: {}}
    );

    const debounceInvalidatePrice = useDebouncedCallback(
        () => dispatch(invalidateProductAndCost()),
        200
    );
    const setTDLDData = useRef(
        debounce(reduceTDLDData, 200, {
            leading: false,
            trailing: true,
            maxWait: 200,
        })
    ).current;

    const reloadIndexedDb = useRef(
        debounce(() => dispatch(invalidateJob()), 200, {
            leading: false,
            trailing: true,
            maxWait: 200,
        })
    ).current;

    const tdldInstance = useRef();
    const helperReferences = useRef({});
    const roomStatusRef = useRef(-1);
    const isProductSelected = useRef(false);
    const triggerSearchRef = useRef(null);

    const setRightSidebarContent = useCallback(() => {
        setAlternateCartContent(
            <LayoutMenu
                jobId={jobId}
                roomId={roomId}
                tdld={tdldInstance.current}
                showLayoutSettings={showLayoutSettings}
                tdldState={tdldState}
                isRoomShapeConfirmed={isRoomShapeConfirmed}
                setSearchCallback={(triggerSearch) => {
                    triggerSearchRef.current = triggerSearch;
                }}
            />
        );
    }, [isRoomShapeConfirmed, showLayoutSettings, roomId, jobId, tdldState]);

    const triggerSearchEvent = (remainingTries: number) => {
        if (triggerSearchRef.current) {
            triggerSearchRef.current();

            return;
        }

        remainingTries--;

        if (remainingTries > 0) {
            setTimeout(() => {
                triggerSearchEvent(remainingTries);
            }, 100);
        }
    };

    const menuButtonHandler = useCallback(
        (name, submenu) => {
            switch (name) {
                case 'FULL_SCREEN':
                    const designerContainer =
                        document.querySelector('#designer');

                    if (designerContainer.classList.contains('fullscreen')) {
                        designerContainer.classList.remove('fullscreen');
                    } else {
                        designerContainer.classList.add('fullscreen');
                    }
                    break;

                case 'CLEAR_ROOM':
                    tdldInstance.current && tdldInstance.current.clearRoom();
                    roomId && setRoomLayoutStatus(roomId, false);
                    setTDLDData({type: 'data', payload: undefined});
                    break;

                case 'ROTATE_ROOM':
                    let rotation = tdldState.tdldData.filter.rotation;

                    rotation = (rotation + 90) % 360;

                    tdldInstance.current &&
                        tdldInstance.current.rotateRoom(rotation);
                    break;

                case 'ADD_DOOR':
                    tdldInstance.current && tdldInstance.current.addDoor();
                    break;

                case 'ADD_WINDOW':
                    tdldInstance.current && tdldInstance.current.addWindow();
                    break;

                case 'ADD_WALL':
                    tdldInstance.current && tdldInstance.current.addInnerWall();
                    break;

                case 'ADD_PLACEHOLDER':
                    let applianceWidth = 600;
                    let applianceDepth;

                    if (submenu) {
                        if (submenu.type == 'base') {
                            applianceDepth = room.baseDepth;
                        }

                        if (submenu.type == 'upper') {
                            applianceDepth = room.upperDepth;
                        }

                        if (submenu.type == 'tall') {
                            applianceDepth = room.tallDepth;
                        }

                        if (submenu.hasOwnProperty('width')) {
                            applianceWidth = submenu.width;
                        }
                    }

                    tdldInstance.current &&
                        tdldInstance.current.addPlaceholder(
                            submenu.text,
                            applianceWidth,
                            applianceDepth
                        );
                    break;

                case 'RECENTER':
                    tdldInstance.current &&
                        tdldInstance.current.centreRoomZoomOutSlightly();
                    break;

                case 'Add_PRODUCT':
                    setRightSidebarContent();
                    openSmallScreenRightSidebar();

                    // Hack to get around event bubbling and other fun things
                    setTimeout(() => {
                        triggerSearchEvent(10);
                    }, 100);
                    break;

                case 'LAYOUT_SETTINGS':
                    setShowLayoutSettings(!showLayoutSettings);
                    break;
            }
        },
        [
            showLayoutSettings,
            setRightSidebarContent,
            roomId,
            tdldInstance,
            tdldState,
            room,
        ]
    );

    useEffect(() => {
        if (
            typeof tdldInstance.current !== 'undefined' &&
            !helperReferences.current.roomShapeSelectionVisible
        ) {
            tdldInstance.current.reload();
        }
    }, [jobId]);

    useEffect(() => {
        setRightSidebarContent();

        return () => {
            setAlternateCartContent(undefined);
        };
    }, [setRightSidebarContent]);

    useLayoutEffect(() => {
        if (!roomId) {
            return;
        }

        if (
            typeof tdldInstance.current !== 'undefined' &&
            tdldInstance.current.roomId == roomId
        ) {
            return;
        }

        hideDialog && hideDialog();

        const uiactions = new UiAction({
            userPrompt: (title, message, buttons, callback) =>
                userPrompt({showDialog, title, message, buttons, callback}),
            showStatus: (statusText, fadeOut) => {
                showStatus({
                    statusText,
                    fadeOut,
                    setStatusMessage,
                    isMounted,
                });
                debounceInvalidatePrice();
            },
            showLoading: () => setLoader(true),
            hideLoading: () => {
                if (
                    roomStatusRef.current > -1 &&
                    tdldInstance.current.data.status > 0
                ) {
                    reloadIndexedDb();
                }

                roomStatusRef.current = tdldInstance.current.data.status;

                if (roomStatusRef.current > 1) {
                    setIsRoomShapeConfirmed(true);
                }
                setLoader(false);
            },
            formatSize: (size) => size,
            addMessage: (message) =>
                showStatus({
                    statusText: message,
                    fadeOut: true,
                    setStatusMessage,
                    isMounted,
                }),
            deleteJobCabinet: () => {
                reloadIndexedDb();
            },
            refreshFilterElements: (tdld) => {
                setTDLDData({type: 'filter', payload: tdld.filter});
            },
            displayProductOptions: (tdld, cabinet) => {
                navigate({
                    search: `?cabinetId=${cabinet.jobCabinetId}`,
                });

                setTDLDData({
                    type: ['data', 'cabinet'],
                    payload: [tdld.data, cabinet],
                });
                isProductSelected.current = true;
            },
            hideProductOptions: (tdld) => {
                setTDLDData({type: 'cabinet', payload: undefined});

                isProductSelected.current = false;
            },
            displayRoomShapeOptions: (tdld, shape, dimension) => {
                if (
                    helperReferences.current.hasOwnProperty(
                        'roomShapeSelectionVisible'
                    ) &&
                    helperReferences.current.roomShapeSelectionVisible
                ) {
                    helperReferences.current.roomShapeSelectionVisible = false;
                    hideDialog();
                }

                setTDLDData({
                    type: ['data', 'shape', 'dimension', 'filter'],
                    payload: [tdld.data, tdld.shape, dimension, tdld.filter],
                });
            },
            displayRoomShapeSelection: (tdld, data) => {
                if (
                    tdld.data.hasOwnProperty('points') &&
                    tdld.data.points.length == 0
                ) {
                    helperReferences.current.roomShapeSelectionVisible = true;
                    roomShapeSelection({
                        showDialog,
                        hideDialog,
                        roomId,
                        tdld,
                        data,
                    });

                    if (roomStatusRef.current > -1) {
                        // reload job data when room is cleared
                        reloadIndexedDb();
                        setIsRoomShapeConfirmed(false);
                    }
                } else {
                    const layoutRoomShapeId =
                        tdld.data.hasOwnProperty('layoutRoomShapeId') &&
                        tdld.data.layoutRoomShapeId;
                    if (layoutRoomShapeId) {
                        const layout = data.find(
                            (shape) => shape.id == layoutRoomShapeId
                        );

                        if (layout) {
                            tdld.onShapeSelected(layout);
                        }
                    }
                }
            },
            layoutRefreshCabinetSummary: () => {
                if (isProductSelected.current) reloadIndexedDb();
            },
        });

        if (typeof tdldInstance.current !== 'undefined') {
            document.querySelector('.tdld_workspace').remove();
        }

        tdldInstance.current = new TDLD('#tdld-container', parseInt(roomId), {
            urlBase: '/api/tdld/',
            uiactions,
        });

        tdldInstance.current.filter.rotateForPortraitPage = true;

        // Remove later after updating TDLD library
        window.tdld = tdldInstance.current;
        window.scaleMin = 0.0;
        // Remove later after updating TDLD library
    }, [userProfile, roomId]);

    return {
        loader: loader,
        dialog,
        isRoomShapeConfirmed,
        menuButtonHandler,
        statusMessage,
    };
};
