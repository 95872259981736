import React from 'react';
import {Spinner} from 'react-bootstrap';
import {useNavigate} from 'shared/reload/helper/useNavigate';
import {useConfirmationDialog} from 'shared';
import {genericMessageHandler, jobId} from 'shared/helpers';
import {copyJob, unSubmitJob, deleteJob} from 'service';
import {useNotificationContext, useAppContext} from 'contexts';
import {jobSearch} from 'hooks';
import {copyJobMessage} from 'components/customer/Job/helpers/getCopyJobMessage';

const dialogHandler = (message = '', loader = false, optMessages = []) => {
    return (
        <>
            {loader && (
                <Spinner
                    animation="border"
                    style={{
                        width: '15px',
                        height: '15px',
                        color: '#AAA',
                    }}></Spinner>
            )}

            <span className="searchText">{message}</span>

            {optMessages?.map((optMsg, index) => {
                return (
                    <div
                        key={index}
                        className={`searchText ${loader && 'opt_message'}`}>
                        {optMsg}
                    </div>
                );
            })}
        </>
    );
};

const jobActionHandlers = () => {
    const {
        refreshDashboard,
        setRefreshDashboard,
        setSuccessDialog,
        userProfile,
    } = useAppContext();
    const {dialog, showDialog, hideDialog} = useConfirmationDialog();
    const {notify} = useNotificationContext();
    const navigate = useNavigate();
    const {resetResults} = jobSearch();

    const deleteJobHandler = async (jobs: object) => {
        const formattedJobId = jobId(jobs.id);

        showDialog({
            title: `Delete Job ID #${formattedJobId}?`,
            message: `Are you sure you want to delete this Job?`,
            keyboard: false,
            yes: async () => {
                try {
                    await deleteJob(jobs.id);
                    resetResults(true);

                    genericMessageHandler(
                        notify,
                        {message: `Job ID #${formattedJobId} was deleted!`},
                        'success'
                    );

                    setRefreshDashboard({status: !refreshDashboard['status']});
                    navigate(`/v2/`);
                } catch (error) {
                    genericMessageHandler(notify, error);
                }
            },
        });
    };

    const showJobStatus = ({
        id,
        webhookResponse,
    }: {
        id: number;
        webhookResponse: string[];
    }) => {
        const formattedJobId = jobId(id);

        const message =
            webhookResponse.length > 1 ? (
                <ul>
                    {webhookResponse.map((r) => (
                        <li key={r}>{r}</li>
                    ))}
                </ul>
            ) : (
                webhookResponse[0]
            );

        showDialog({
            title: `Status of #${formattedJobId}?`,
            message: message,
            keyboard: false,
            hideYesButton: true,
            hideNoButton: true,
            buttons: [
                {
                    name: 'Ok',
                    show: true,
                    variant: 'primary',
                    action: () => {
                        hideDialog();
                    },
                },
            ],
        });
    };

    const unSubmitJobHandler = (jobs: {id: number}) => {
        const formattedJobId = jobId(jobs.id);

        showDialog({
            title: `Unsubmit Job ID #${formattedJobId}?`,
            message: `Are you sure you want to unsubmit this job for further edits?`,
            keyboard: false,
            yes: async () => {
                try {
                    await unSubmitJob(jobs.id);
                    resetResults(true);
                    genericMessageHandler(
                        notify,
                        {
                            message: `Unsubmitted Job ID #${formattedJobId} Successfully!`,
                        },
                        'success'
                    );

                    setRefreshDashboard({status: !refreshDashboard['status']});

                    setSuccessDialog([
                        {
                            status: true,
                            message: `Successfully unsubmitted <b>Job ID #${formattedJobId}</b><br />Do you want to continue to edit this job?`,
                            link: `/v2/job/${formattedJobId}/dashboard`,
                        },
                    ]);

                    navigate(`/v2/`);
                } catch (error) {
                    genericMessageHandler(notify, error);
                }
            },
        });
    };

    const editJobHandler = (jobs: object) => {
        const formattedJobId = jobId(jobs.id);
        resetResults(true);
        navigate(`/v2/job/${formattedJobId}/dashboard`);
    };

    const copyJobHandler = async (jobs: object) => {
        const formattedJobId = jobId(jobs.id);

        showDialog({
            title: `Copy Job ID #${formattedJobId}?`,
            message: copyJobMessage(jobs, userProfile),
            hideYesButton: true,
            hideNoButton: true,
            keyboard: false,
            buttons: [
                {
                    name: `Copy Job #${formattedJobId}`,
                    show: true,
                    action: async () => {
                        showDialog({
                            title: `One moment please...`,
                            message: dialogHandler(
                                `Copying JobID# ${formattedJobId}`,
                                true,
                                [``, `Page will refresh upon completion`]
                            ),
                            hideYesButton: true,
                            hideNoButton: true,
                            keyboard: false,
                        });
                        
                        let copiedJob = await copyJob(jobs.id);
                        resetResults(true);

                        setRefreshDashboard({
                            status: !refreshDashboard['status'],
                        });
                        
                        setSuccessDialog([
                            {
                                status: true,
                                message: `Successfully copied <b>Job ID #${formattedJobId}</b> to <b>NEW Job ID #${copiedJob['jobId']}</b><br />Do you want to continue to edit the newly copied job?`,
                                link: `/v2/job/${copiedJob['jobId']}/dashboard`,
                            },
                        ]);

                        hideDialog();
                        navigate(`/v2/`);
                    },
                },
                {
                    name: 'Close',
                    show: true,
                    variant: 'danger',
                    action: () => {
                        hideDialog();
                    },
                },
            ],
        });
    };

    return {
        navigate,
        showDialog,
        dialog,
        copyJobHandler,
        unSubmitJobHandler,
        deleteJobHandler,
        editJobHandler,
        showJobStatus,
    };
};

export {jobActionHandlers, dialogHandler};
