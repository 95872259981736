import {useEffect, useState} from 'react';
import {useNotificationContext} from 'contexts';
import {genericMessageHandler, usePromise} from 'shared/helpers';
import {getGapSizeDefaults} from 'service';

// NOTE: Setting page is using this hook.
// Update that to use rtkquery and remove this hook.
export const useMarginDefaultsData = () => {
    const [loader, setLoader] = useState(false);
    const {notify} = useNotificationContext();
    const [gapSizes, setGapSizes] = useState([]);
    const [_, refreshGap] = useState({});

    useEffect(() => {
        setLoader(true);
        const resetCache = _.hasOwnProperty('noCache') ? _.noCache : false;
        const gapSizePromise = getGapSizeDefaults(resetCache);

        return usePromise(
            ([gapSizes]) => {
                setGapSizes(
                    gapSizes.map((gapSize) => ({
                        upperTopGap: gapSize.upperTopGap,
                        upperBottomGap: gapSize.upperBottomGap,
                        ...gapSize,
                    }))
                );
                setLoader(false);
            },
            [gapSizePromise],
            (error) => {
                genericMessageHandler(notify, error);
                setLoader(false);
            }
        );
    }, [_]);

    return {
        loader,
        setLoader,
        gapSizes,
        refreshGap,
    };
};
