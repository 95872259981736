import {useJobContext} from 'contexts';
import {useEffect, useMemo, useRef, useState} from 'react';
import {getProductConfig} from 'service';
import {getCabinetFields, usePromise} from 'shared/helpers';
import {cloneDeep} from 'lodash';

export const useProductOptions = ({tdld, tdldState}) => {
    const {room} = useJobContext();
    const fields = useRef([
        {
            name: 'quantity',
            label: 'Quantity',
            display: false,
            type: 'number',
        },
        {
            type: 'label',
            value: '(Modifying dimension alters all)',
            display: false,
        },
        {
            name: 'height',
            label: 'Height',
            display: false,
            type: 'number',
        },
        {
            name: 'width2',
            label: 'Left Width',
            display: false,
            type: 'number',
        },
        {
            name: 'width',
            label: 'Right Width',
            display: false,
            type: 'number',
        },
        {
            name: 'width',
            label: 'Width',
            display: false,
            type: 'number',
        },
        {
            name: 'widthDepth',
            label: 'Left Depth',
            display: false,
            type: 'number',
        },
        {
            name: 'lengthDepth',
            label: 'Right Depth',
            display: false,
            type: 'number',
        },
        {
            name: 'depth',
            label: 'Depth',
            display: false,
            type: 'number',
        },
        {
            name: 'length1',
            label: 'Length 1',
            display: false,
            type: 'number',
        },
        {
            name: 'length2',
            label: 'Length 2',
            display: false,
            type: 'number',
        },
        {
            name: ['x', 'y'],
            label: 'Location',
            display: false,
            type: 'location',
        },
        {
            name: 'rotation',
            label: 'Rotation',
            display: false,
            type: 'angle',
        },
        {
            name: 'mirrored',
            label: 'Mirrored',
            display: false,
            type: 'boolean',
        },
    ]).current;

    const style = useMemo(() => {
        return {
            backgroundImage: `url("/uploads/gocabinet_materials/${
                room && room.extMaterial && room.extMaterial.image != ''
                    ? room.extMaterial.image
                    : 'default_exterior.jpg'
            }")`,
        };
    }, [room]);

    const [productFields, setProductFields] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const tdldData = tdldState.tdldData;
        if (tdldData && tdldData.cabinet) {
            setLoading(true);

            let productPromise;
            if (tdldData.cabinet.jobCabinetId == 0) {
                productPromise = (() => {
                    return {isProduct: false};
                })();
            } else {
                productPromise = (async () => {
                    const productConfig = await getProductConfig(
                        tdldData.cabinet.type
                    );

                    return {
                        isProduct: true,
                        config: productConfig,
                    };
                })();
            }

            return usePromise(
                ([{isProduct, config}]) => {
                    let cabinetFields;

                    if (isProduct) {
                        const {fields: fields_} = getCabinetFields(
                            config.structure,
                            true
                        );
                        cabinetFields = Object.values(fields_);
                    }

                    const mappedFields = cloneDeep(fields).map((field) => {
                        if (field.type === 'label') {
                            if (
                                tdldData.cabinet.hasOwnProperty('quantity') &&
                                tdldData.cabinet.quantity > 1
                            )
                                field.display = true;

                            return field;
                        } else if (field.type == 'number') {
                            if (
                                isProduct &&
                                cabinetFields.includes(field.label)
                            ) {
                                field.display = true;
                                field.value = tdldData.cabinet[field.name];
                            }

                            if (
                                !isProduct &&
                                tdldData.cabinet.hasOwnProperty(field.name)
                            ) {
                                if (field.label == 'Right Width') {
                                    return field;
                                }

                                field.display = true;
                                field.value = tdldData.cabinet[field.name];
                            }
                        } else if (field.type == 'location') {
                            field.value = field.name.map((name) => {
                                if (tdldData.cabinet.hasOwnProperty(name)) {
                                    field.display = true;
                                    return tdldData.cabinet[name];
                                } else {
                                    return 0;
                                }
                            });
                        } else {
                            if (tdldData.cabinet.hasOwnProperty(field.name)) {
                                field.display = true;
                                field.value = tdldData.cabinet[field.name];
                            }
                        }

                        return field;
                    });

                    setProductFields(mappedFields);

                    setLoading(false);
                },
                [productPromise],
                (error, cancel) => {
                    setLoading(false);
                }
            );
        }
    }, [tdldState]);

    return {
        style,
        productFields,
        loading,
    };
};
