import {useAppContext} from 'contexts';
import {useState} from 'react';

interface Materials {
    hingeStyle?: number;
    drawerSystem?: number;
    cabinetTop?: number;
    exteriorType?: number;
    exteriorBrandMaterial?: number;
    exteriorCategory?: number;
    exteriorStyle?: number;
    exteriorBrand?: number;
    exteriorFinish?: string;
    exteriorSubstrate?: string;
    exteriorColor?: number;
    exteriorEdgeBrand?: number;
    exteriorEdgeFinish?: number;
    exteriorEdgeColor?: number;
    exteriorColorHorizontal?: boolean;
    exteriorColorDoubleSided?: boolean;
    exteriorColorCustomName?: string;
    carcaseType?: number;
    carcaseBrand?: number;
    carcaseFinish?: string;
    carcaseSubstrate?: string;
    carcaseColor?: number;
    carcaseEdgeBrand?: number;
    carcaseEdgeFinish?: number;
    carcaseEdgeColor?: number;
    carcaseColorHorizontal?: boolean;
    carcaseColorDoubleSided?: boolean;
    carcaseColorCustomName?: string;
}

export const useMaterialDefaultSettings = (): {
    initialValues: Materials;
} => {
    const {userProfile} = useAppContext();
    const [initialValues] = useState<Materials>({
        hingeStyle: userProfile.defaultHinge,
        drawerSystem: userProfile.defaultDrawer,
        cabinetTop: userProfile.defaultRoomTop,

        exteriorType: userProfile.defaultExtMaterialType,
        exteriorBrandMaterial: userProfile.defaultExtBrand,
        exteriorCategory: userProfile.defaultDoorCategory,
        exteriorStyle: userProfile.defaultDoor,

        exteriorBrand: userProfile.defaultExtBrand,
        exteriorFinish: userProfile.defaultExtFinish,
        exteriorSubstrate: userProfile.defaultExtSubstrate,
        exteriorColor: userProfile.defaultExtMaterial,
        exteriorEdgeBrand: userProfile.defaultExtEdgeBrand,
        exteriorEdgeFinish: userProfile.defaultExtEdgeFinish,
        exteriorEdgeColor: userProfile.defaultExtEdge,
        exteriorColorHorizontal: userProfile.defaultExtHorGrain == 1,
        exteriorColorDoubleSided: userProfile.defaultExtDoubleSided == 1,
        exteriorColorCustomName: userProfile.defaultExtCustomColor,

        carcaseType: userProfile.defaultCarcMaterialType,
        carcaseBrand: userProfile.defaultCarcBrand,
        carcaseFinish: userProfile.defaultCarcFinish,
        carcaseSubstrate: userProfile.defaultCarcSubstrate,
        carcaseColor: userProfile.defaultCarcMaterial,
        carcaseEdgeBrand: userProfile.defaultCarcEdgeBrand,
        carcaseEdgeFinish: userProfile.defaultCarcEdgeFinish,
        carcaseEdgeColor: userProfile.defaultCarcEdge,
        carcaseColorHorizontal: userProfile.defaultCarcHorGrain == 1,
        carcaseColorDoubleSided: userProfile.defaultCarcDoubleSided == 1,
        carcaseColorCustomName: userProfile.defaultCarcCustomColor,
    });

    return {
        initialValues,
    };
};
